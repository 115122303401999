import React from 'react';
import './Login.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';


export default function Login() {
  return (
    <div className="login">
        <div className="banner-container">
            <div className="banner-header-container">
                <div className="logo">
                    <div className="banner-header">
                        <img src="calcutek_logo.png" />
                    </div>
                    <h1 className="banner-subtext">Under Construction</h1>
                    <h1 className="banner-subtext-two">Coming Fall 2023</h1>
                </div>
            </div>
        </div>
        {/* <div className="form-container">
            <div className="login-form">
                    <h1>Login</h1>
                    <div className="username-password">
                        <div>
                            <TextField inputProps={{ style: { background: "white" } }} fullWidth id="outlined-basic" label="Username" variant="filled" />
                        </div>
                        <div>
                            <TextField  inputProps={{ style: { background: "white" } }} fullWidth id="outlined-basic" label="Password" variant="filled" />
                        </div>
                    </div>
                    <div className="forgot-password">
                        <div><Checkbox defaultChecked /> Remember Me</div>
                        <div className="need-account">Need an account? Sign up</div>
                    </div>
                    <div>
                    <Button fullWidth sx={{backgroundColor: '#7FB2DC', textTransform: 'none'}} variant="contained">Login</Button>
                    </div>
            </div>
        </div> */}
    </div>
  );
}
